import React from "react";
import {Link as ScrollLink} from "react-scroll";
import * as Scroll from "react-scroll";
import {NavLink as RouterLink, useLocation, useNavigate} from "react-router-dom";
import {CookieBanner} from '@keepist/react-gdpr-cookie-banner';
import {scrollDuration} from "../config/commonConfig";

let currentYear = new Date().getFullYear();
const message = <div><span className='d-block fw-bold mb-2'>
    We value your privacy</span> <span> We use cookies to enhance your browsing experience.<br/>By clicking “Accept All” you consent to our use of cookies.</span>
</div>


const Footer = () => {

    const path = useLocation().pathname;
    const location = path.split("/")[1];
    const navigate = useNavigate();
    const scroller = Scroll.scroller;

    const goToPageAndScroll = async (page, selector) => {
        await navigate(page);
        await scroller.scrollTo(selector, {
            duration: 500,
            smooth: true,
            offset: -300,
            spy: true
        });
    };

    return (
        <footer id="footer" className="section bg-green text-white">
            <div className="container f5">
                <div className="row">
                    <div className="col-sm-12 text-center wow zoomIn">
                        <img
                            className="img-fluid"
                            src="images/fact-5.png"
                            title="Fibroids increase incidences of infertility, preterm delivery, and postpartum hemorrhaging."
                            alt="Fibroids increase incidences of infertility, preterm delivery, and postpartum hemorrhaging."
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-8 offset-lg-2 text-sm-start wow fadeInUp bg-green px-3 py-5 mt-sm-5">
                <p className="m-sm-5 py-sm-5 d-sm-none">&nbsp;</p>
                <p className="m-sm-5 py-sm-5 d-sm-none">&nbsp;</p>
                <p className="m-sm-5 py-sm-5 d-sm-none">&nbsp;</p>
                <p className="mb-5 mb-lg-0 text-5 text-sm-start text-lg-center">
                    The Content available on this site is not intended to be a substitute for professional
                    medical diagnosis, treatment, and advice. If you think you may have a medical emergency,
                    call your doctor, go to the emergency department, or call 911 immediately.
                </p>
                <p className="mb-5 mb-lg-0 text-5 text-sm-start text-lg-center mt-5">
                    Referenced fibroid prevalence and impact research data courtesy of: Lou, Z., Huang, Y., Li, S. et
                    al. Global, regional, and national time trends in incidence, prevalence, years lived with disability
                    for uterine fibroids, 1990–2019: an age-period-cohort analysis for the global burden of disease 2019
                    study. BMC Public Health 23, 916 (2023). <a href="https://doi.org/10.1186/s12889-023-15765-x"
                                                                target="_blank">https://doi.org/10.1186/s12889-023-15765-x</a>
                </p>
                <address className="mt-5 text-5 text-center">
                    6047 Christie Avenue<br/>Emeryville, CA 94608
                </address>
                <p className="text-5 text-center mb-5">
                    info@AltinBiosciences.com
                </p>
                <p className="text-5 text-center m2-5">
                    <RouterLink
                        className="mx-3"
                        to="/privacy-policy"
                        onClick={() => goToPageAndScroll("/privacy-policy", "content")}
                    >Privacy policy</RouterLink>
                    <RouterLink
                        className="mx-3"
                        to="/terms-conditions"
                        onClick={() => goToPageAndScroll("/terms-conditions", "content")}
                    >Terms and Conditions</RouterLink>
                </p>
                <p className="mb-2 mb-lg-0 text-5 text-center">
                    Copyright © {currentYear} {" "}
                    {/*<a className="fw-600" href="/">*/}
                    {/*  Altin Biosciences*/}
                    {/*</a>*/}
                    Altin Biosciences. All Rights Reserved.
                </p>

            </div>
            {/*<div className="col-lg-6 wow fadeInUp">*/}
            {/*  <p className="mb-0 text-center text-lg-end">*/}
            {/*    Designed by{" "}*/}
            {/*    <a className="fw-600" href="https://www.harnishdesign.net/">*/}
            {/*      Harnish Design*/}
            {/*    </a>*/}
            {/*  </p>*/}
            {/*</div>*/}

            <CookieBanner
                message={message}
                styles={{
                    dialog: {
                        position: 'fixed',
                        fontSize: '20px',
                        zIndex: 999,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: '25px',
                        color: "#4B4B4B",
                        background: '#BFC4CC',
                        borderRadius: "20px 20px 0px 0px"
                    },
                    button: {
                        classname: "btn btn-light"
                    }
                }}

                acceptAllButtonText="Accept All"
                showAcceptSelectionButton={true}
                acceptSelectionButtonText="Reject All"
                wholeDomain={true}
                showStatisticsOption={false}
                showMarketingOption={false}
                policyLink="/privacy-policy"
                onAccept={() => {
                }}
                onAcceptPreferences={() => {
                }}
                onAcceptStatistics={() => {
                }}
                onAcceptMarketing={() => {
                }}
            />


        </footer>
    );
};

export default Footer;
