import React from "react";
import {scrollDuration} from "../config/commonConfig";
import {Link} from "react-scroll";

const AboutUs = () => {
    return (<section id="about" className="section bg-altin">
        <div className="bubble-green-leaves"></div>
        <div className="bubble-green"></div>
        <div className="container">
            <div className="row">
                <div className="col-lg-10 offset-lg-1 wow fadeInUp">
                    {/* Heading */}
                    <h3 className="text-9 fw-400 text-center mb-5 wow fadeInUp">
                        They power through fibroids. But at what cost?
                    </h3>
                    <p className="text-5 text-altin-dark-gray text-center mb-5">
                        Painful, heavy periods are common but <em>not</em> normal. Uterine fibroids may cause these and other
                        debilitating symptoms that significantly impact quality of life, fertility, relationships,
                        careers and finances.
                    </p>
                    <h1 className="text-15 fw-400 text-center mb-5 wow fadeInUp">
                        Altin Biosciences develops first-in-class botanical drug candidates for global unmet medical
                        needs.
                    </h1>
                    {/* Heading end*/}

                    <p className="text-5 text-altin-dark-gray text-center mb-5">
                        First-in-class, non-hormonal ABC-105/205 is for the treatment of uterine fibroids and is
                        designed to be both fertility and pregnancy-friendly. This represents a paradigm shift in
                        the
                        treatment of uterine fibroids and a significant opportunity to improve the lives of females,
                        reduce reliance on hysterectomy for fibroids, and positively impact fertility and maternal
                        mortality worldwide.
                    </p>
                    <p className="mt-lg-5">&nbsp;</p>
                    <h2 className="text-11 fw-400 text-center mt-5 wow fadeInUp">
                        Medical breakthrough for fibroids: ABC-105/205
                    </h2>
                </div>
            </div>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1 wow fadeInUp">
                        <div className="row align-items-center mb-5">
                            <div className="col-md-4 text-center">
                                <h3 className="text-9 fw-400 text-center mb-sm-2">
                                    Rigorous <br />studies
                                </h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <h3 className="text-9 fw-400 text-center mb-sm-2">
                                    Synergistic <br />results
                                </h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <h3 className="text-9 fw-400 text-center mb-sm-2">
                                    Botanically <br />derived
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-8 offset-lg-2 wow fadeInUp mt-2">
                <p className="text-5 text-altin-dark-gray text-center mb-5">
                    Ground-breaking research on ABC-105/205, conducted by Dr. Ayman Al-Hendy at the University of
                    Chicago in 2022, was recently published in the medical
                    journal&nbsp;<em>Fertility&nbsp;&&nbsp;Sterility&nbsp;Science</em>.
                </p>
                <p className="text-5 text-altin-dark-gray text-center mb-5">
                    Research shows the patent-pending combination of natural compounds crinum latifolium (Crila®)
                    and epigallocatechin gallate (EGCG) showed enhanced antiproliferative (suppresses cell growth)
                    effects and apoptosis (cell death) on human uterine fibroid cells
                    compared&nbsp;to&nbsp;single&nbsp;treatments.
                </p>
                <p className="text-5 text-altin-dark-gray text-center mb-5">
                    <a href="https://authors.elsevier.com/sd/article/S2666335X23000526" target="_blank"
                       className="btn text-5 m-3">
                        Read the research
                    </a>
                </p>
                <h2 className="text-11 fw-400 text-center mt-5 mb-3 wow fadeInUp">
                    Seed to science
                </h2>
                <p className="text-5 text-altin-dark-gray text-center mb-5">
                    Our drug candidates are subject to FDA regulation. Pre-clinical and clinical trials will
                    evaluate safety and efficacy as required for all FDA-licensed drugs.
                </p>
            </div>
            <div className="col-lg-6 offset-lg-3 wow fadeInUp">
                <p className="text-7 text-altin-dark-gray text-left mb-4 mt-5 lh-sm">
                    The hypotheses we are testing:
                </p>
                <p className="text-7 text-altin-blue fw-600 text-left mb-2">
                    ABC-105/205:
                </p>
                <ul className="text-7 text-altin-blue fw-600 text-left mb-5">
                    <li>shrinks and may eliminate uterine fibroids</li>
                    <li>is fertility and pregnancy-friendly</li>
                    <li>provides an effective alternative to invasive surgery</li>
                </ul>
            </div>
        </div>
    </section>);
};

export default AboutUs;
