import React from "react";
import { Link } from "react-scroll";
import { Parallax } from "react-parallax";
import { scrollDuration } from "../config/commonConfig";

const CallToAction = () => {
  return (
    <section>
      <div className="hero-wrap call-to-action">
        <div className="container f6">
          <div className="col-sm-12 text-center wow zoomIn">
            <img
                className="img-fluid"
                src="images/fact-6.png"
                title="Fibroids increase incidences of infertility, preterm delivery, and postpartum hemorrhaging."
                alt="Fibroids increase incidences of infertility, preterm delivery, and postpartum hemorrhaging."
            />
          </div>
        </div>
        <img
            className="f5-lg"
            src="images/fact-5.png"
            title="Fibroids increase incidences of infertility, preterm delivery, and postpartum hemorrhaging."
            alt="Fibroids increase incidences of infertility, preterm delivery, and postpartum hemorrhaging."
        />
        <img
            className="f6-lg"
            src="images/fact-6.png"
            title="Fibroids - The most common reason for hysterectomy"
            alt="Fibroids - The most common reason for hysterectomy"
        />
        <div className="hero-mask" />
        <Parallax
          bgImage="images/intro-bg.jpg"
          bgImageAlt="Intro"
          strength={200}
        >
          <div className="hero-content section">
            <div className="container text-center py-5 px-5 fadeInUp">
              <div className="row py-lg-5">
                <div className="col-md-8 offset-md-2">
                  <h2 className="text-11 fw-600 text-white mb-5 mt-5">
                    We all know someone with fibroids
                  </h2>
                  <p className="text-7 text-center text-white lh-base">
                    <span className="line">One of the most impactful things you can do to support </span>
                    <span className="line">innovation in female health is to be informed.</span>
                  </p>
                  <p className="text-7 text-center text-white lh-base mb-5">
                    <span className="line">Sign up and receive email updates on our progress, including </span>
                    <span className="line">clinical trial recruitment announcements.</span>
                  </p>
                  <Link
                      className="btn btn-trans rounded-5 mt-4 smooth-scroll wow rubberBand"
                      data-wow-delay="1s"
                      smooth="easeInOutQuint"
                      duration={scrollDuration}
                      to="contact"
                  >
                    Keep me updated!
                  </Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
    </section>
  );
};

export default CallToAction;
