import React, {useRef, useState} from "react";
import emailjs from "@emailjs/browser";
import {ToastContainer, toast} from "react-toastify";
import Mailchimp from "../config/mailchimp";

import "react-toastify/dist/ReactToastify.css";
import {Tooltip} from "./Tooltip";

const Contact = () => {
    return (
        <section id="contact" className="section bg-primary mb-5 mt-5">
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-lg-2"></div>
                    <div
                        className="col-lg-8 col-md-12 ms-auto mt-5 mt-lg-5 wow fadeInUp"
                        data-wow-delay="0.3s"
                    >
                        <h2 className="text-10 text-altin-blue fw-400 text-center mb-5">
                            Keeping you informed
                        </h2>
                        <Mailchimp
                            action='https://AltinBiosciences.us21.list-manage.com/subscribe/post?u=7ec0433ef444a90ea629fcc91&amp;id=f43942165d&amp;f_id=00f4dbe6f0'
                            className="form-border"
                            fields={[
                                {
                                    name: 'FNAME',
                                    title: 'First name:',
                                    type: 'text',
                                    required: true
                                },
                                {
                                    name: 'LNAME',
                                    title: 'Last name:',
                                    type: 'text',
                                    required: false
                                },
                                {
                                    name: 'EMAIL',
                                    title: 'Email address',
                                    type: 'email',
                                    required: true
                                },
                                {
                                    name: 'group',
                                    title: "I'm interested in",
                                    type: 'group',
                                    choices: [
                                        {
                                            name: 'group[1766][2]',
                                            title: 'Participating in clinical trials',
                                            type: 'checkbox',
                                            required: false,
                                            id: 'mce-group[1766]-1766-0'
                                        },
                                        {
                                            name: 'group[1766][4]',
                                            title: 'Updates on company news and milestones',
                                            type: 'checkbox',
                                            required: false,
                                            id: 'mce-group[1766]-1766-1'
                                        },
                                        {
                                            name: 'group[1766][8]',
                                            title: 'Supporting female health innovation',
                                            type: 'checkbox',
                                            required: false,
                                            id: 'mce-group[1766]-1766-2'
                                        },
                                        {
                                            name: 'group[1766][16]',
                                            title: 'Botanical drug development',
                                            type: 'checkbox',
                                            required: false,
                                            id: 'mce-group[1766]-1766-3'
                                        }
                                    ]
                                }
                            ]}
                            buttonClassName={'btn btn-light'}
                        />

                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
