import React, {useEffect, useState} from "react";
import Accordion from "react-bootstrap/Accordion";
import {appliedConfig, introBackgroundConfig, themeConfig} from "../config/commonConfig";
import WOW from "wowjs";
import BottomHeader from "./themes/bottomHeader/Header";
import FullScreenHeader from "./themes/fullScreen/Header";
import StandardMenuHeader from "./themes/StandardMenu/Header";

import Footer from "./Footer";
import {Tooltip} from "./Tooltip";

const Home = () => {
    const appliedTheme = appliedConfig.appliedTheme;
    const appliedIntro = appliedConfig.appliedIntro;

    const handleNavClick = (section) => {
        document.getElementById(section).scrollIntoView({behavior: "smooth"});
    };

    const [scrollTopVisible, setScrollTopVisible] = useState(false);

    useEffect(() => {
        new WOW.WOW({
            live: false,
        }).init();
    }, []);

    const checkScrollTop = () => {
        let scrollTopBtn = document.getElementById("back-to-top");

        if (scrollTopBtn) {
            if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
                setScrollTopVisible(true);
            } else {
                setScrollTopVisible(false);
            }
        }
    };

    if (typeof window !== "undefined") {
        window.addEventListener("scroll", checkScrollTop);
    }

    const getHeader = () => {
        if (appliedTheme === themeConfig.BottomHeader) {
            return <BottomHeader></BottomHeader>;
        } else if (appliedTheme === themeConfig.FullScreenMenu) {
            return (<FullScreenHeader
                    textWhite={appliedIntro === introBackgroundConfig.image}
                ></FullScreenHeader>);
        } else {
            return <StandardMenuHeader></StandardMenuHeader>;
        }
    };


    return (<div style={{position: "relative"}}>
            <div id="main-wrapper">

                {getHeader()}

                <div id="content" role="main" className="page-node">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-xl-8 offset-xl-2 col-sm-12 text-5 pb-5">
                                <h1>Terms and Conditions</h1>
                                <p>Welcome to the website of Altin Biosciences Corporation, a company based in
                                    California,
                                    USA. We are committed to protecting your privacy and ensuring that you understand
                                    how we
                                    collect, use, and share your personal information.</p>

                                <p className="pb-3">This Terms and Conditions Policy (the "Policy") applies to all users
                                    of our website,
                                    including those who simply browse the website and those who provide us with their
                                    email
                                    addresses to receive opt-in marketing communications.</p>

                                <h2>Collection of Personal Information</h2>

                                <p className="pb-3">The only personal information we collect on our website is your
                                    email address. We
                                    collect this information when you voluntarily submit it to us by signing up for our
                                    email list.</p>

                                <h2>Use of Personal Information</h2>

                                <p>We use your email address to send you opt-in marketing communications, such as
                                    newsletters, promotions, and updates about our company and products.</p>

                                <p className="pb-3">We will not sell, rent, or share your email address with any third
                                    parties without your
                                    consent.</p>

                                <h2>Opt-Out</h2>

                                <p className="pb-3">You can unsubscribe from our email list at any time by clicking on
                                    the unsubscribe link
                                    at the bottom of any email we send you. You can also unsubscribe by contacting us at
                                    [email address].</p>

                                <h2>Changes to the Policy</h2>

                                <p className="pb-3">We may update this Policy from time to time. If we make any material
                                    changes, we will
                                    post a notice on our website and send you an email notification (if you have
                                    subscribed
                                    to our email list).</p>

                                <h2>Contact Us</h2>

                                <p className="pb-3">If you have any questions about this Policy, please contact us at
                                    <a href="mailto:info@altinbiosciences.com">info@altinbiosciences.com</a>.</p>

                                <h2>Effective Date</h2>

                                <p className="pb-3">This Policy is effective as of September 27, 2023.</p>

                                <h2>Additional Information for California Residents</h2>

                                <p>Under the California Consumer Privacy Act (CCPA), California residents have certain
                                    rights with respect to their personal information. For example, you have the right
                                    to
                                    request access to your personal information, to have it deleted, and to opt out of
                                    the
                                    sale of your personal information.</p>

                                <p>To exercise your CCPA rights, please contact us at <a
                                    href="mailto:info@altinbiosciences.com">info@altinbiosciences.com</a>.</p>

                                <p className="pb-3">We will not sell your personal information.</p>

                                <h2>Consent</h2>

                                <p>By using our website, you agree to the terms of this Policy.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer handleNavClick={handleNavClick}></Footer>
            </div>
            {/* back to top */}
            <Tooltip text="Back to Top" placement="left">
        <span
            id="back-to-top"
            className="rounded-circle"
            style={{display: scrollTopVisible ? "inline" : "none"}}
            onClick={() => {
                window.scrollTo({top: 0, behavior: "smooth"});
            }}
        >
          <i className="fas fa-arrow-up"></i>
        </span>
            </Tooltip>
        </div>);
};

export default Home;
