import React from "react";
import videobgDesktop from "../../../videos/home-video-h-d.mp4";
import videobgMobile from "../../../videos/home-video-m.mp4";
import Typewriter from "typewriter-effect";
import { Link } from "react-scroll";
import { scrollDuration } from "../../../config/commonConfig";
import "../../../sass/hero-bubbles.scss";

const StandardMenuVideoBgIntro = () => {
  console.log(window.innerWidth);
  let videobg = videobgDesktop;
  if (window.innerWidth < 425) {
    videobg = videobgMobile;
  } else {
    videobg = videobgDesktop;
  }
  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-1 bg-primary" />
        <div className="player hero-bg ">
          <video
            src={videobg}
            autoPlay
            muted
            loop
            playsInline
          ></video>
        </div>
        <div className="hero-content d-flex fullscreen-with-header">
          <div className="container better-bubbles">
            <div className="row row1">
              <div className="col-6 text-center">
                <img
                    className="img-fluid f3 wow zoomIn"
                    src="images/fact-3.png"
                    title="Up to 80% of females will develop fibroids by age 50"
                    alt="Up to 80% of females will develop fibroids by age 50"
                />
              </div>
              <div className="col-6 text-center">
                <img
                  className="img-fluid f1 wow zoomIn"
                  src="images/fact-1.png"
                  title="Fibroid incidence is 3x greater among Black women."
                  alt="Fibroid incidence is 3x greater among Black women."
              />
              </div>
            </div>
            <div className="row row2">
              <div className="col-6 text-center">
                <img
                    className="img-fluid f2 wow zoomIn"
                    src="images/fact-2.png"
                    title="Fibroid incidence is 3x greater among Black women."
                    alt="Fibroid incidence is 3x greater among Black women."
                />
              </div>
              <div className="col-6 text-center">
                <img
                    className="img-fluid f4 wow zoomIn"
                    src="images/fact-4.png"
                    title="Females waited an average of 3.6 years before seeking treatment for fibroid-related symptoms"
                    alt="Females waited an average of 3.6 years before seeking treatment for fibroid-related symptoms"
                />
              </div>
            </div>
          </div>
          {/*<div className="facts facts-all wow zoomIn"></div>*/}
          {/*<div className="facts fact-2 wow zoomIn">*/}
          {/*  <img*/}
          {/*      className=""*/}
          {/*      src="images/fact-2.png"*/}
          {/*      title="Fibroid incidence is 3x greater among Black women."*/}
          {/*      alt="Fibroid incidence is 3x greater among Black women."*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className="facts fact-3 wow zoomIn">*/}
          {/*  <img*/}
          {/*      className=""*/}
          {/*      src="images/fact-3.png"*/}
          {/*      title="Up to 80% of females will develop fibroids by age 50"*/}
          {/*      alt="Up to 80% of females will develop fibroids by age 50"*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className="facts fact-4 wow zoomIn">*/}
          {/*  <img*/}
          {/*      className=""*/}
          {/*      src="images/fact-4.png"*/}
          {/*      title="Females waited an average of 3.6 years before seeking treatment for fibroid-related symptoms"*/}
          {/*      alt="Females waited an average of 3.6 years before seeking treatment for fibroid-related symptoms"*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="fact-bubbles container">
            <div className="col-sm-12 text-center fact-3 wow zoomIn">
              <img
                  className="img-fluid f3"
                  src="images/fact-3.png"
                  title="Up to 80% of females will develop fibroids by age 50"
                  alt="Up to 80% of females will develop fibroids by age 50"
              />
              <img
                  className="img-fluid f1"
                  src="images/fact-1.png"
                  title="Fibroid incidence is 3x greater among Black women."
                  alt="Fibroid incidence is 3x greater among Black women."
              />
              <img
                  className="img-fluid f2"
                  src="images/fact-2.png"
                  title="Fibroid incidence is 3x greater among Black women."
                  alt="Fibroid incidence is 3x greater among Black women."
              />
              <img
                  className="img-fluid f4"
                  src="images/fact-4.png"
                  title="Females waited an average of 3.6 years before seeking treatment for fibroid-related symptoms"
                  alt="Females waited an average of 3.6 years before seeking treatment for fibroid-related symptoms"
              />
            </div>
          </div>
          <img
              className="marquee-curve"
              src="images/marquee-curve.png"
              title=""
              alt=""
          />

        </div>
      </div>
    </section>
  );
};

export default StandardMenuVideoBgIntro;
