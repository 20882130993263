import "./App.scss";

import {
  BrowserRouter, Routes,
  Route
} from 'react-router-dom';

import Home from "./components/Home";
import PrivacyPolicy from "./components/Privacy";
import Terms from "./components/Terms";

function App() {


  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />}></Route>
          <Route exact path='/terms-conditions' element={<Terms />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
