import React, {useEffect, useState} from "react";
import Accordion from "react-bootstrap/Accordion";
import {appliedConfig, introBackgroundConfig, themeConfig} from "../config/commonConfig";
import WOW from "wowjs";
import BottomHeader from "./themes/bottomHeader/Header";
import FullScreenHeader from "./themes/fullScreen/Header";
import StandardMenuHeader from "./themes/StandardMenu/Header";

import Footer from "./Footer";
import {Tooltip} from "./Tooltip";

const Home = () => {
    const appliedTheme = appliedConfig.appliedTheme;
    const appliedIntro = appliedConfig.appliedIntro;

    const handleNavClick = (section) => {
        document.getElementById(section).scrollIntoView({behavior: "smooth"});
    };

    const [scrollTopVisible, setScrollTopVisible] = useState(false);

    useEffect(() => {
        new WOW.WOW({
            live: false,
        }).init();
    }, []);

    const checkScrollTop = () => {
        let scrollTopBtn = document.getElementById("back-to-top");

        if (scrollTopBtn) {
            if (
                document.body.scrollTop > 400 ||
                document.documentElement.scrollTop > 400
            ) {
                setScrollTopVisible(true);
            } else {
                setScrollTopVisible(false);
            }
        }
    };

    if (typeof window !== "undefined") {
        window.addEventListener("scroll", checkScrollTop);
    }

    const getHeader = () => {
        if (appliedTheme === themeConfig.BottomHeader) {
            return <BottomHeader></BottomHeader>;
        } else if (appliedTheme === themeConfig.FullScreenMenu) {
            return (
                <FullScreenHeader
                    textWhite={appliedIntro === introBackgroundConfig.image}
                ></FullScreenHeader>
            );
        } else {
            return <StandardMenuHeader></StandardMenuHeader>;
        }
    };


    return (
        <div style={{position: "relative"}}>
            <div id="main-wrapper">

                {getHeader()}

                <div id="content" role="main" className="page-node">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-xl-8 offset-xl-2 col-sm-12 text-5 pb-5">
                                <h1>Privacy Policy</h1>
                                <p>Effective Date: September 27, 2023</p>

                                <h2>Introduction</h2>

                                <p className="pb-3">Altin Biosciences Corporation ("we" or "us") is a company based in
                                    California, USA and is committed to protecting your privacy. This Privacy Policy
                                    explains how we collect, use, share, and protect your personal information when you
                                    visit our website or use our services.</p>

                                <h2>What Personal Information Do We Collect?</h2>

                                <p>We collect personal information from you in a variety of ways, including:</p>

                                <ul>
                                    <li>When you create an account or sign up for our mailing list</li>
                                    <li>When you contact us for customer support</li>
                                    <li>When you interact with our website or services</li>
                                </ul>
                                <p className="pb-3">The personal information we collect may include your name, email
                                    address, mailing address, phone number, and payment information.</p>

                                <h2>How Do We Use Your Personal Information?</h2>

                                <p>We use your personal information for a variety of purposes, including:</p>

                                <ul className="pb-3">
                                    <li>To provide you with the services you have requested</li>
                                    <li>To communicate with you about our services</li>
                                    <li>To improve our website and services</li>
                                    <li>To send you marketing communications, such as newsletters and promotions (if you
                                        have opted in to receive them)
                                    </li>
                                </ul>
                                <h2>How Do We Share Your Personal Information?</h2>

                                <p>We may share your personal information with third-party service providers who help us
                                    operate our website and provide our services. For example, we may share your
                                    personal information with email service providers.</p>

                                <p className="pb-3">We may also share your personal information with third parties if we
                                    are required to do so by law or if we believe that it is necessary to protect the
                                    safety or security of our website, our users, or our employees.</p>

                                <h2>Your Choices</h2>

                                <p>You have the following choices regarding your personal information:</p>

                                <ul className="pb-3">
                                    <li>You can choose not to provide us with any personal information. However, this
                                        may limit your ability to use our website and services.
                                    </li>
                                    <li>You can choose to opt out of receiving marketing communications from us. To do
                                        this, please click on the unsubscribe link at the bottom of any marketing email
                                        we send you.
                                    </li>
                                    <li>You can request access to your personal information, have it corrected or
                                        deleted, or object to our processing of your personal information. To do this,
                                        please contact us at [email address].
                                    </li>
                                </ul>
                                <h2>Data Security</h2>

                                <p className="pb-3">We take reasonable steps to protect your personal information from
                                    unauthorized access, use, or disclosure. However, no method of data transmission or
                                    storage is completely secure. Therefore, we cannot guarantee the absolute security
                                    of your personal information.</p>

                                <h2>Changes to This Privacy Policy</h2>

                                <p className="pb-3">We may update this Privacy Policy from time to time. If we make any
                                    material changes, we will post a notice on our website and send you an email
                                    notification (if you have subscribed to our email list).</p>

                                <h2>Contact Us</h2>

                                <p className="pb-3">If you have any questions about this Privacy Policy, please contact
                                    us at <a href="mailto:info@altinbiosciences.com">info@altinbiosciences.com</a>.</p>

                                <h2>Additional Information for California Residents</h2>

                                <p>Under the California Consumer Privacy Act (CCPA), California residents have certain
                                    rights with respect to their personal information. For example, you have the right
                                    to request access to your personal information, to have it deleted, and to opt out
                                    of the sale of your personal information.</p>

                                <p>To exercise your CCPA rights, please contact us at <a
                                    href="mailto:info@altinbiosciences.com">info@altinbiosciences.com</a>.</p>

                                <p>We will not sell your personal information.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer handleNavClick={handleNavClick}></Footer>
            </div>
            {/* back to top */}
            <Tooltip text="Back to Top" placement="left">
        <span
            id="back-to-top"
            className="rounded-circle"
            style={{display: scrollTopVisible ? "inline" : "none"}}
            onClick={() => {
                window.scrollTo({top: 0, behavior: "smooth"});
            }}
        >
          <i className="fas fa-arrow-up"></i>
        </span>
            </Tooltip>
        </div>
    );
};

export default Home;
