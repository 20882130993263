import React from "react"
import jsonp from "jsonp"
import PropTypes from 'prop-types';

class Mailchimp extends React.Component {
    state = {};

    handleSubmit(evt) {
        evt.preventDefault();
        const {fields, action} = this.props;
        const values = fields.map(field => {
            if (field.type != 'group') {
                return `${field.name}=${encodeURIComponent(this.state[field.name])}`;
            }
        }).join("&");
        const interestCollection = [];
        if (evt.target[3].checked) {
            interestCollection.push(evt.target[3].name)
        }
        if (evt.target[4].checked) {
            interestCollection.push(evt.target[4].name)
        }
        if (evt.target[5].checked) {
            interestCollection.push(evt.target[5].name)
        }
        if (evt.target[6].checked) {
            interestCollection.push(evt.target[6].name)
        }
        const interest = interestCollection.map(field => {
            return `${field}=${encodeURIComponent(this.state[field])}`;
        }).join("&");

        const path = `${action}&${values}&${interest}`;
        const url = path.replace('/post?', '/post-json?');
        const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/;
        const email = this.state['EMAIL'];
        (!regex.test(email)) ? this.setState({status: "empty"}) : this.sendData(url);
    };

    sendData(url) {
        this.setState({status: "sending"});
        jsonp(url, {param: "c"}, (err, data) => {
            // console.log(data.msg);
            // console.log(err);
            if (data.msg.includes("already subscribed")) {
                this.setState({status: 'duplicate'});
            } else if (data.msg.includes('has too many recent signup requests')) {
                this.setState({status: 'duplicate'});
            } else if (err) {
                this.setState({status: 'error'});
            } else if (data.result !== 'success') {
                this.setState({status: 'error'});
            } else {
                this.setState({status: 'success'});
            };
        });
    }

    render() {
        const {fields, styles, className, buttonClassName} = this.props;
        const messages = {
            ...Mailchimp.defaultProps.messages,
            ...this.props.messages
        }
        const {status} = this.state;
        return (
            <form onSubmit={this.handleSubmit.bind(this)} className={className}>
                <div className="row g-4">
                    {React.Children.toArray(
                        fields.map(input =>
                            input.type != 'group' ?
                                <>
                                    <div className="col-12">
                                        <label className="form-label text-5"
                                               htmlFor={input.id}>{input.title}</label>
                                        <input
                                            {...input}
                                            key={input.name}
                                            onChange={({target}) => this.setState({[input.name]: target.value})}
                                            defaultValue={this.state[input.name]}
                                            className="form-control py-1"
                                        />
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-12 form-interest">
                                        <label className="form-label text-5">{input.title}</label>
                                        {React.Children.toArray(
                                            input.choices.map(input =>
                                                <div className="col-10 px-lg-5 text-5 mb-4">
                                                    <input
                                                        {...input}
                                                        key={input.name}
                                                        onChange={({target}) => this.setState({[input.name]: target.value})}
                                                        defaultValue={this.state[input.name]}
                                                        id={input.id}
                                                    />
                                                    <label htmlFor={input.id}>{input.title}</label>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </>
                        )
                    )}
                    <div className="Toastify"></div>
                </div>
                <div className="col-12 px-lg-5 text-center text-lg-start">
                    <button
                        disabled={status === "sending" || status === "success"}
                        type="submit"
                        className={buttonClassName}
                    >
                        {messages.button}
                        <span className="ms-3">
                      <i className="fas fa-arrow-right"/>
                    </span>
                    </button>
                </div>
                <div className='msg-alert text-8 text-left pt-5'>
                    {status === "sending" && <p className="lh-base" style={styles.sendingMsg}>{messages.sending}</p>}
                    {status === "success" && <p className="lh-base"  style={styles.successMsg}>{messages.success}</p>}
                    {status === "duplicate" && <p className="lh-base"  style={styles.duplicateMsg}>{messages.duplicate}</p>}
                    {status === "empty" && <p className="lh-base"  style={styles.errorMsg}>{messages.empty}</p>}
                    {status === "error" && <p className="lh-base"  style={styles.errorMsg}>{messages.error}</p>}
                </div>
            </form>
        );
    }
}

Mailchimp.defaultProps = {
    messages: {
        sending: "Sending...",
        success: "Thank you! Your message has been received.",
        error: "Sorry, an error occured, please try again.",
        empty: "You must write an e-mail.",
        duplicate: "This email address is already subscribed.",
        button: "SUBMIT"
    },
    buttonClassName: "",
    styles: {
        sendingMsg: {
            color: "#6D9895"
        },
        successMsg: {
            color: "#6D9895"
        },
        duplicateMsg: {
            color: "#A30600"
        },
        errorMsg: {
            color: "#A30600"
        }
    }
};

Mailchimp.propTypes = {
    action: PropTypes.string,
    messages: PropTypes.object,
    fields: PropTypes.array,
    styles: PropTypes.object,
    className: PropTypes.string,
    buttonClassName: PropTypes.string
};

export default Mailchimp;