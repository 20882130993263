import React, {useEffect, useState} from "react";
import Accordion from "react-bootstrap/Accordion";
import {appliedConfig, introBackgroundConfig, themeConfig} from "../config/commonConfig";
import WOW from "wowjs";
import BottomHeader from "./themes/bottomHeader/Header";
import FullScreenHeader from "./themes/fullScreen/Header";
import StandardMenuHeader from "./themes/StandardMenu/Header";
import BottomHeaderDefaultIntro from "./themes/bottomHeader/IntroDefault";
import BottomHeaderImgBgIntro from "./themes/bottomHeader/IntroImageBg";
import BottomHeaderVideoBgIntro from "./themes/bottomHeader/IntroVideoBg";
import FullScreenDefaultIntro from "./themes/fullScreen/IntroDefault";
import FullScreenImgBgIntro from "./themes/fullScreen/IntroImageBg";
import FullScreenVideoBgIntro from "./themes/fullScreen/IntroVideoBg";
import StandardMenuDefaultIntro from "./themes/StandardMenu/IntroDefault";
import StandardMenuImgBgIntro from "./themes/StandardMenu/IntroImageBg";
import StandardMenuVideoBgIntro from "./themes/StandardMenu/IntroVideoBg";

import AboutUs from "./About";
import Contact from "./Contact";
import Footer from "./Footer";
import { Tooltip } from "./Tooltip";
import CallToAction from "./CallToAction";

const Home = () => {
    const appliedTheme = appliedConfig.appliedTheme;
    const appliedIntro = appliedConfig.appliedIntro;

    const handleNavClick = (section) => {
        document.getElementById(section).scrollIntoView({ behavior: "smooth" });
    };

    const [scrollTopVisible, setScrollTopVisible] = useState(false);

    useEffect(() => {
        new WOW.WOW({
            live: false,
        }).init();
    }, []);

    const checkScrollTop = () => {
        let scrollTopBtn = document.getElementById("back-to-top");

        if (scrollTopBtn) {
            if (
                document.body.scrollTop > 400 ||
                document.documentElement.scrollTop > 400
            ) {
                setScrollTopVisible(true);
            } else {
                setScrollTopVisible(false);
            }
        }
    };

    if (typeof window !== "undefined") {
        window.addEventListener("scroll", checkScrollTop);
    }

    const getHeader = () => {
        if (appliedTheme === themeConfig.BottomHeader) {
            return <BottomHeader></BottomHeader>;
        } else if (appliedTheme === themeConfig.FullScreenMenu) {
            return (
                <FullScreenHeader
                    textWhite={appliedIntro === introBackgroundConfig.image}
                ></FullScreenHeader>
            );
        } else {
            return <StandardMenuHeader></StandardMenuHeader>;
        }
    };

    const getBottomHeaderIntro = () => {
        if (appliedIntro === introBackgroundConfig.default) {
            return <BottomHeaderDefaultIntro></BottomHeaderDefaultIntro>;
        } else if (appliedIntro === introBackgroundConfig.image) {
            return <BottomHeaderImgBgIntro></BottomHeaderImgBgIntro>;
        } else {
            return <BottomHeaderVideoBgIntro></BottomHeaderVideoBgIntro>;
        }
    };

    const getFullScreenIntro = () => {
        if (appliedIntro === introBackgroundConfig.default) {
            return <FullScreenDefaultIntro></FullScreenDefaultIntro>;
        } else if (appliedIntro === introBackgroundConfig.image) {
            return <FullScreenImgBgIntro></FullScreenImgBgIntro>;
        } else {
            return <FullScreenVideoBgIntro></FullScreenVideoBgIntro>;
        }
    };

    const getStandardMenuIntro = () => {
        if (appliedIntro === introBackgroundConfig.default) {
            return <StandardMenuDefaultIntro></StandardMenuDefaultIntro>;
        } else if (appliedIntro === introBackgroundConfig.image) {
            return <StandardMenuImgBgIntro></StandardMenuImgBgIntro>;
        } else {
            return <StandardMenuVideoBgIntro></StandardMenuVideoBgIntro>;
        }
    };
    return (
        <div style={{ position: "relative" }}>
          <div id="main-wrapper">
            {appliedTheme === themeConfig.BottomHeader && getBottomHeaderIntro()}
            {getHeader()}

            <div id="content" role="main">
              {appliedTheme === themeConfig.FullScreenMenu &&
                  getFullScreenIntro()}
              {appliedTheme === themeConfig.StandardMenu &&
                  getStandardMenuIntro()}

              <AboutUs></AboutUs>
              {/*<Services></Services>*/}
              {/*<Resume></Resume>*/}
              {/*<Portfolio></Portfolio>*/}
              <CallToAction></CallToAction>
              {/*<FAQs></FAQs>*/}
              {/*<Testimonials></Testimonials>*/}
              <Contact></Contact>
            </div>
            <Footer handleNavClick={handleNavClick}></Footer>
          </div>
          {/* back to top */}
          <Tooltip text="Back to Top" placement="left">
        <span
            id="back-to-top"
            className="rounded-circle"
            style={{ display: scrollTopVisible ? "inline" : "none" }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
        >
          <i className="fas fa-arrow-up"></i>
        </span>
          </Tooltip>
        </div>
    );
};

export default Home;
