import React, {useState} from "react";
import {Link as ScrollLink} from "react-scroll";
import * as Scroll from "react-scroll";
import {NavLink as RouterLink, useLocation, useNavigate} from "react-router-dom";
import {scrollDuration} from "../../../config/commonConfig";
import {Tooltip} from "../../Tooltip";


const StandardMenuHeader = () => {
    const [isNavModalClose, setIsNavModalClose] = useState(true);

    const path = useLocation().pathname;
    const location = path.split("/")[1];
    const navigate = useNavigate();
    const scroller = Scroll.scroller;

    const goToPageAndScroll = async (page, selector) => {
        await navigate(page);
        await scroller.scrollTo(selector, {
            duration: 500,
            smooth: true,
            offset: -75,
            spy: true
        });
    };

    const useScrollDirection = () => {
        const [scrollDirection, setScrollDirection] = React.useState(null);

        React.useEffect(() => {
            let lastScrollY = window.pageYOffset;

            const updateScrollDirection = () => {
                const scrollY = window.pageYOffset;
                const direction = scrollY > lastScrollY ? "down" : "up";
                if (direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
                    setScrollDirection(direction);
                }
                lastScrollY = scrollY > 0 ? scrollY : 0;
            };
            window.addEventListener("scroll", updateScrollDirection); // add event listener
            return () => {
                window.removeEventListener("scroll", updateScrollDirection); // clean up
            }
        }, [scrollDirection]);

        return scrollDirection;
    };

    const scrollDirection = useScrollDirection();

    return (
        <header id="header" className={`sticky-top ${ scrollDirection === "down" ? "small" : ""}`}>
            {/* Navbar */}
            <nav className="primary-menu navbar navbar-expand-lg text-uppercase navbar-line-under-text fw-600">
                <div className="container position-relative">
                    <div className="col-6 text-center p-3">
                        {/* Logo */}
                        <RouterLink
                            className="logo"
                            to="/"
                            onClick={() => goToPageAndScroll("/", "home")}
                        >
                            <img src="images/logo.png" alt="Altin Biosciences" className="img-fluid"/>
                        </RouterLink>
                        {/* Logo End */}
                    </div>
                    <div className="col-6">
                        <button
                            className={
                                "navbar-toggler ms-auto collapsed " +
                                (isNavModalClose ? "" : "show")
                            }
                            type="button"
                            onClick={() => setIsNavModalClose(!isNavModalClose)}
                        >
                            <span/>
                            <span/>
                            <span/>
                        </button>
                        <div
                            id="header-nav"
                            className={
                                "collapse navbar-collapse justify-content-center " +
                                (isNavModalClose ? "" : "show")
                            }
                        >
                            <ul className="navbar-nav">
                                {/*<li className="nav-item">*/}
                                {/*  <Link*/}
                                {/*    className="nav-link "*/}
                                {/*    smooth="easeInOutQuint"*/}
                                {/*    duration={scrollDuration}*/}
                                {/*    style={{ cursor: "pointer" }}*/}
                                {/*    activeClass="active"*/}
                                {/*    spy*/}
                                {/*    to="home"*/}
                                {/*    offset={-71}*/}
                                {/*    onClick={(e) => {*/}
                                {/*      e.preventDefault();*/}
                                {/*      setIsNavModalClose(true);*/}
                                {/*    }}*/}
                                {/*  >*/}
                                {/*    Home*/}
                                {/*  </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                {/*  <Link*/}
                                {/*    className="nav-link "*/}
                                {/*    smooth="easeInOutQuint"*/}
                                {/*    duration={scrollDuration}*/}
                                {/*    style={{ cursor: "pointer" }}*/}
                                {/*    activeClass="active"*/}
                                {/*    spy*/}
                                {/*    to="about"*/}
                                {/*    onClick={(e) => {*/}
                                {/*      e.preventDefault();*/}
                                {/*      setIsNavModalClose(true);*/}
                                {/*    }}*/}
                                {/*  >*/}
                                {/*    About*/}
                                {/*  </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                {/*  <Link*/}
                                {/*    className="nav-link "*/}
                                {/*    smooth="easeInOutQuint"*/}
                                {/*    duration={scrollDuration}*/}
                                {/*    style={{ cursor: "pointer" }}*/}
                                {/*    activeClass="active"*/}
                                {/*    spy*/}
                                {/*    to="services"*/}
                                {/*    onClick={(e) => {*/}
                                {/*      e.preventDefault();*/}
                                {/*      setIsNavModalClose(true);*/}
                                {/*    }}*/}
                                {/*  >*/}
                                {/*    Services*/}
                                {/*  </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                {/*  <Link*/}
                                {/*    className="nav-link "*/}
                                {/*    smooth="easeInOutQuint"*/}
                                {/*    duration={scrollDuration}*/}
                                {/*    style={{ cursor: "pointer" }}*/}
                                {/*    activeClass="active"*/}
                                {/*    spy*/}
                                {/*    to="resume"*/}
                                {/*    onClick={(e) => {*/}
                                {/*      e.preventDefault();*/}
                                {/*      setIsNavModalClose(true);*/}
                                {/*    }}*/}
                                {/*  >*/}
                                {/*    Resume*/}
                                {/*  </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                {/*  <Link*/}
                                {/*    className="nav-link "*/}
                                {/*    smooth="easeInOutQuint"*/}
                                {/*    duration={scrollDuration}*/}
                                {/*    style={{ cursor: "pointer" }}*/}
                                {/*    activeClass="active"*/}
                                {/*    spy*/}
                                {/*    to="portfolio"*/}
                                {/*    onClick={(e) => {*/}
                                {/*      e.preventDefault();*/}
                                {/*      setIsNavModalClose(true);*/}
                                {/*    }}*/}
                                {/*  >*/}
                                {/*    Portfolio*/}
                                {/*  </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                {/*  <Link*/}
                                {/*    className="nav-link "*/}
                                {/*    smooth="easeInOutQuint"*/}
                                {/*    duration={scrollDuration}*/}
                                {/*    style={{ cursor: "pointer" }}*/}
                                {/*    activeClass="active"*/}
                                {/*    spy*/}
                                {/*    to="faq"*/}
                                {/*    onClick={(e) => {*/}
                                {/*      e.preventDefault();*/}
                                {/*      setIsNavModalClose(true);*/}
                                {/*    }}*/}
                                {/*  >*/}
                                {/*    FAQ*/}
                                {/*  </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                {/*  <Link*/}
                                {/*    className="nav-link "*/}
                                {/*    smooth="easeInOutQuint"*/}
                                {/*    duration={scrollDuration}*/}
                                {/*    style={{ cursor: "pointer" }}*/}
                                {/*    activeClass="active"*/}
                                {/*    spy*/}
                                {/*    to="testimonial"*/}
                                {/*    onClick={(e) => {*/}
                                {/*      e.preventDefault();*/}
                                {/*      setIsNavModalClose(true);*/}
                                {/*    }}*/}
                                {/*  >*/}
                                {/*    Client*/}
                                {/*  </Link>*/}
                                {/*</li>*/}
                                <li className="nav-item">
                                    <button className="nav-link btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsNavModalClose(true);
                                            goToPageAndScroll("/", "contact")
                                        }
                                    }
                                        >Contact</button>
                                </li>
                            </ul>
                        </div>
                    </div>


                    {/*<div className="col-auto col-lg-2 d-flex justify-content-end ps-0">*/}
                    {/*  <ul className="social-icons">*/}
                    {/*    <li className="social-icons-facebook">*/}
                    {/*      <Tooltip text="Twitter" placement="bottom">*/}
                    {/*        <a*/}
                    {/*          href="http://www.facebook.com/"*/}
                    {/*          target="_blank"*/}
                    {/*          rel="noopener noreferrer"*/}
                    {/*        >*/}
                    {/*          <i className="fab fa-facebook" />*/}
                    {/*        </a>*/}
                    {/*      </Tooltip>*/}
                    {/*    </li>*/}
                    {/*    <li className="social-icons-twitter">*/}
                    {/*      <Tooltip text="Twitter" placement="bottom">*/}
                    {/*        <a*/}
                    {/*          href="http://www.twitter.com/"*/}
                    {/*          target="_blank"*/}
                    {/*          rel="noopener noreferrer"*/}
                    {/*        >*/}
                    {/*          <i className="fab fa-twitter" />*/}
                    {/*        </a>*/}
                    {/*      </Tooltip>*/}
                    {/*    </li>*/}
                    {/*    <li className="social-icons-instagram">*/}
                    {/*      <Tooltip text="Twitter" placement="bottom">*/}
                    {/*        <a*/}
                    {/*          href="http://www.instagram.com/"*/}
                    {/*          target="_blank"*/}
                    {/*          rel="noopener noreferrer"*/}
                    {/*        >*/}
                    {/*          <i className="fab fa-instagram" />*/}
                    {/*        </a>*/}
                    {/*      </Tooltip>*/}
                    {/*    </li>*/}
                    {/*  </ul>*/}
                    {/*</div>*/}
                </div>
            </nav>
            {/* Navbar End */}
        </header>
    );
};

export default StandardMenuHeader;
